// tagging.ts
import { SEGMENT_WRITE_KEY } from '~config/config-global';
import { baseUrl } from '../config/base-url';
import * as Sentry from "@sentry/nextjs";
import {
  clearStoredValue,
  getLocalStorage,
  getQueryParam,
  getQueryParamURL,
  setLocalStorage,
  storeGetValue,
} from './cookie';
import { AnalyticsBrowser } from '@segment/analytics-next'

export const analytics = new AnalyticsBrowser()
analytics.load({ writeKey: SEGMENT_WRITE_KEY })

// maybe use some helper code to capture initial urls before router rewrite or any interaction that modifies it
//
// <script>
//   try {window.onloadLocationHref = "" + window.location.href; } catch (e) {}
//   try {window.onloadReferrer = document.referrer? "" + document.referrer : ""  } catch (e) {}
//   try {window.onloadCookie =  document.cookie? "" + document.cookie : "" } catch (e) {}
// </script>

type FacebookVariables = {
  fbpfbc: {
    fbc: string | undefined;
    fbp: string | undefined;
  };
  event_id: string;
};

const generateFbcFromFbclid = (fbclid: string): string =>
  `fb.1.${Date.now()}.${fbclid}`;

const generateFallbackFbc = (): string =>
  `fb.1.${Date.now()}.${Math.random().toString(36).substring(2, 15)}`;

const generateFbpWithRandomNumber = (): string =>
  `fb.1.${Math.floor(Date.now())}.${Math.random().toString(10).substring(2, 12)}`;

// export const getFacebookVariables = (): FacebookVariables => {
//   let fbc = storeGetValue(undefined, '_fbc', 365); // or input can come from cookie
//   if (!fbc) {
//     const fbclid = getQueryParam('fbclid'); // input can come from url param
//     if (fbclid) fbc = generateFbcFromFbclid(fbclid);
//     else fbc =  generateFallbackFbc();
//     fbc = storeGetValue(fbc, '_fbc', 365);
//   }

//   let fbp = storeGetValue(undefined, '_fbp', 365); // input can come only from cookie
//   if (!fbp) {
//     fbp = generateFbpWithRandomNumber();
//     fbp = storeGetValue(fbp, '_fbp', 365);
//   }

//   const event_id: string = `evt-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
//   const fbpfbc = { fbc, fbp };
//   return { fbpfbc, event_id };
// };

export const getFacebookVariables = (): FacebookVariables => {
  // First try to get stored fbc
  let fbc = storeGetValue(undefined, '_fbc', 90);

  // If no stored fbc, check for fbclid in URL
  if (!fbc) {
    const fbclid = getQueryParam('fbclid');
    if (fbclid) {
      // Only generate fbc if we have a valid fbclid
      fbc = `fb.1.${Math.floor(Date.now())}.${fbclid}`;
      fbc = storeGetValue(fbc, '_fbc', 90);
    }
  }

  let fbp = storeGetValue(undefined, '_fbp', 365);
  if (!fbp) {
    fbp = generateFbpWithRandomNumber();
    fbp = storeGetValue(fbp, '_fbp', 365);
  }

  const event_id: string = `evt-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
  const fbpfbc = {
    fbc: fbc || undefined, // Only include fbc if we have it
    fbp
  };

  return { fbpfbc, event_id };
};

// referial id
export function getReferralId() {
  let referral = getQueryParam('id');
  const referralId = storeGetValue(referral, 'referral', 30);

  if (referralId != referral && referral) {
    storeFirstEnterLink();
    storeFirstReferralLink();
  }
  return referralId;
}

export function clearReferralId() {
  clearStoredValue('referral');
}

export function getUTMMedium(): string | undefined {
  const parameterName = 'utm_medium';
  let parameterValue = getQueryParam(parameterName);
  if (!parameterValue) {
    const link = getFirstEnterLink();
    if (link) parameterValue = getQueryParamURL(link, parameterName);
  }
  return storeGetValue(parameterValue, parameterName, 30);
}

export function getUTMSource(): string | undefined {
  const parameterName = 'utm_source';
  let parameterValue = getQueryParam(parameterName);
  if (!parameterValue) {
    const link = getFirstEnterLink();
    if (link) parameterValue = getQueryParamURL(link, parameterName);
  }
  return storeGetValue(parameterValue, parameterName, 30);
}

export function getUTMCampaign(): string | undefined {
  const parameterName = 'utm_campaign';
  let parameterValue = getQueryParam(parameterName);

  if (!parameterValue) {
    const link = getFirstEnterLink();
    if (link) parameterValue = getQueryParamURL(link, parameterName);
  } else {
    // if receving url with a utm_campaign parameter reset the FirstEnterLink
    storeFirstEnterLink();
    storeFirstReferralLink();
  }
  return storeGetValue(parameterValue, parameterName, 30);
}

export function getUTMAd(): string | undefined {
  const parameterName = 'utm_ad';
  let parameterValue = getQueryParam(parameterName);
  if (!parameterValue) {
    const link = getFirstEnterLink();
    if (link) parameterValue = getQueryParamURL(link, parameterName);
  }
  return storeGetValue(parameterValue, parameterName, 30);
}

export function getUTMAdSet(): string | undefined {
  const parameterName = 'utm_adset';
  let parameterValue = getQueryParam(parameterName);
  if (!parameterValue) {
    const link = getFirstEnterLink();
    if (link) parameterValue = getQueryParamURL(link, parameterName);
  }
  return storeGetValue(parameterValue, parameterName, 30);
}

export function getUTM(): {
  UTM_Medium: string | undefined;
  UTM_Source: string | undefined;
  UTM_Campaign: string | undefined;
  UTM_Ad: string | undefined;
  UTM_Ad_Set: string | undefined;
} {
  return {
    UTM_Medium: getUTMMedium(),
    UTM_Source: getUTMSource(),
    UTM_Campaign: getUTMCampaign(),
    UTM_Ad: getUTMAd(),
    UTM_Ad_Set: getUTMAdSet(),
  };
}

export function getFirstEnterLink(): string | undefined {
  const parameterName = 'FirstEnterLink';
  let storedValue = getLocalStorage(parameterName);
  if (!storedValue) {
    storedValue = ((window as any).onloadLocationHref as string) || window.location.href;
    setLocalStorage(parameterName, storedValue);
  }
  return storedValue;
}

export function storeFirstEnterLink(): void {
  const parameterName = 'FirstEnterLink';
  let storedValue =
    ((window as any).onloadLocationHref as string) || window.location.href;
  if (storedValue) setLocalStorage(parameterName, storedValue);
}

export function storeFirstReferralLink(): void {
  const parameterName = 'FirstReferralLink';
  let storedValue =
    ((window as any).onloadReferrer as string) ||
    window.document.referrer ||
    'none';
  if (storedValue) setLocalStorage(parameterName, storedValue);
}

export function getFirstReferralLink(): string | undefined {
  const parameterName = 'FirstReferralLink';
  let storedValue = getLocalStorage(parameterName);
  if (!storedValue) {
    storedValue =
      ((window as any).onloadReferrer as string) ||
      window.document.referrer ||
      'none';
    setLocalStorage(parameterName, storedValue);
  }
  return storedValue;
}

export function getFirstCookie(): string | undefined {
  const parameterName = 'FirstCookie';
  let storedValue = getLocalStorage(parameterName);
  if (!storedValue) {
    let tryGetCookie = undefined;
    try {
      tryGetCookie = '' + document.cookie;
    } catch (e) { }
    storedValue =
      ((window as any).onloadCookie as string) || tryGetCookie || 'none';
    setLocalStorage(parameterName, storedValue);
  }
  return storedValue;
}

export function getMarketingDebugData(): {
  First_Enter_Link: string | undefined;
  First_Referral_Link: string | undefined;
  First_Cookie: string | undefined;
} {
  return {
    First_Enter_Link: getFirstEnterLink(),
    First_Referral_Link: getFirstReferralLink(),
    First_Cookie: getFirstCookie(),
  };
}

export function getTestEventCode(): string | undefined {
  let parameterValue = getQueryParam('test_event_code');
  if (parameterValue) {
    sessionStorage.setItem('test_event_code', parameterValue);
  }
  return sessionStorage.getItem('test_event_code') || undefined;
}

export const sendTaggingFacebookEvent = async (
  facebook: any
): Promise<void> => {
  const url = baseUrl! + '/api/tagging/send-event';
  const options = {
    method: 'POST',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ facebook }),
  };
  try {
    const response = await (fetch as any)(url, options);
    const data = await response.json();
    if (data.data.facebook._messages.length > 0) {
      console.log('Tagging event sent successfully:', data);
      try {
        throw new Error('Facebook Error:\n' + data.data.facebook._messages.join('\n'));
      } catch (error) {
        Sentry.captureException(error);
      }
      // Sentry.captureMessage(data.data.facebook._messages.join('\n'), "error");
    }
  } catch (error) {
    console.error('Error sending tagging event:', error);
  }
};

const default_content_category: string = 'Gifts';
const default_content_name: string = 'Original Song';
const default_content_ids: Array<string> = ['S000001'];
const default_contents: Array<{ id: string; quantity: number }> = [
  { id: 'S000001', quantity: 1 },
];
const default_content_type = 'product';
const default_currency = 'USD';
const default_value = '199';


export const sendSegmentIdentify = async (userid: string, data: { name?: string, email?: string } = {}): Promise<void> => {
  analytics.identify(userid, data);
}

export const sendTaggingFacebookPixelCustomEvent = async (
  event_name: string, //  = 'CustomEventName'
  custom_data: {
    [k: string]: any;
  }, // {content_name:"", order_id:"", ... }
  eventID: string // deduplication facebook event id
): Promise<void> => {
  try {
    if ((window as any)?.fbq)
      (window as any).fbq(
        'trackCustom',
        event_name,
        custom_data,
        // {
        //     // content_name,
        //     // content_type,
        //     // content_ids,
        //     // contents,
        //     // currency,
        //     // value,
        //     ...(transaction_id ? { 'order_id': transaction_id } : {}),
        // }
        {
          eventID,
        }
      );
  } catch (e) {
    console.log(e);
  }
};

export const sendTaggingFacebookPixelStandardEvent = async (
  event_name: string, //  = 'CustomEventName'
  custom_data: {
    [k: string]: any;
  }, // {content_name:"", order_id:"", ... }
  eventID: string // deduplication facebook event id
): Promise<void> => {
  try {
    if ((window as any)?.fbq)
      (window as any).fbq(
        'track',
        event_name,
        custom_data,
        // {
        //     // content_name,
        //     // content_type,
        //     // content_ids,
        //     // contents,
        //     // currency,
        //     // value,
        //     ...(transaction_id ? { 'order_id': transaction_id } : {}),
        // }
        {
          eventID,
        }
      );
  } catch (e) {
    console.log(e);
  }
};

export const sendTaggingFacebookPixelEvent = async (
  event_name: string, //  = 'CustomEventName'
  custom_data: {
    [k: string]: any;
  }, // {content_name:"", order_id:"", ... }
  eventID: string // deduplication facebook event id
): Promise<void> => {
  try {
    if ((window as any)?.fbq)
      (window as any).fbq(
        'track',
        event_name,
        custom_data,
        // {
        //     // content_name,
        //     // content_type,
        //     // content_ids,
        //     // contents,
        //     // currency,
        //     // value,
        //     ...(transaction_id ? { 'order_id': transaction_id } : {}),
        // }
        {
          eventID,
        }
      );
  } catch (e) {
    console.log(e);
  }
};

export const sendTaggingFacebookIdentify = async (
  content_name: string = window.document.title,
  email_hash: string | undefined = undefined
): Promise<void> => {
  const fbVariables = getFacebookVariables();
  sendTaggingFacebookEvent({
    data: [
      {
        test_event_code: getTestEventCode(),
        event_name: 'ViewContent',
        // "event_time": 1702150504,  // set by server
        action_source: 'website',
        event_id: fbVariables.event_id,
        event_source_url: location.href,
        user_data: {
          ...fbVariables.fbpfbc,
          ...(email_hash ? { email: email_hash } : {}),
          // "client_ip_address": null, // set by server
          // "client_user_agent": null // set by server
        },
        custom_data: {
          content_name,
        },
      },
    ],
  });
};

export const sendTaggingCustomEvent = async ({
  event_name,
  content_category = default_content_category,
  content_name = document.title,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
  num_items = 1,
}: {
  event_name: string;
  content_category?: string;
  content_name?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
  num_items?: number;
}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  await Promise.all([
    async () => {
      try {
        const pagesense = ((window as any).pagesense as any[]) || [];
        pagesense.push(['trackEvent', event_name]);
      } catch (e) {
        console.error(e?.stack ? e?.stack : e);
      }
    },

    async () => {
      try {
        analytics.track(event_name, {
          name: content_name.substring(0, 50),
          product_id: content_ids[0],
          category: content_category,
          properties: {
            currency,
            revenue: value,
          },
          messageId: fbVariables.event_id,
        });
      } catch (e) {
        console.error(e?.stack ? e?.stack : e);
      }
    },

    sendTaggingFacebookPixelCustomEvent(
      event_name,
      {
        content_category,
        content_name,
        content_type,
        content_ids,
        contents,
        currency,
        value,
        num_items,
        ...(transaction_id ? { order_id: transaction_id } : {}),
      },
      fbVariables.event_id
    ), // Promise.all

    //facebook
    sendTaggingFacebookEvent({
      data: [
        {
          test_event_code: getTestEventCode(),
          // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
          // Required for Advantage+ catalog ads: contents or content_ids

          event_name,
          // "event_time": 1702150504,  // set by server
          action_source: 'website',
          event_id: fbVariables.event_id,
          event_source_url: location.href,
          user_data: {
            ...fbVariables.fbpfbc,
            // ...(email ? { email: await hashEmail(email) } : {})
            // "client_ip_address": null, // set by server
            // "client_user_agent": null // set by server
          },
          custom_data: {
            content_category,
            content_name,
            content_type,
            content_ids,
            contents,
            currency,
            num_items,
            ...(transaction_id ? { order_id: transaction_id } : {}),
          },
        },
      ],
    }),
  ]);
};

export const sendTaggingViewContentPage = async ({
  content_name = document.title,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
  fbVariables = undefined
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
  fbVariables?: FacebookVariables;
} = {}): Promise<void> => {

  if (!fbVariables)
    fbVariables = getFacebookVariables();

  //facebook
  sendTaggingFacebookEvent({
    data: [
      {
        test_event_code: getTestEventCode(),
        // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
        // Required for Advantage+ catalog ads: contents or content_ids

        event_name: 'ViewContent',
        // "event_time": 1702150504,  // set by server
        action_source: 'website',
        event_id: fbVariables.event_id,
        event_source_url: location.href,
        user_data: {
          ...fbVariables.fbpfbc,
          //...(email ? { email: await hashEmail(email) } : {})
          // "client_ip_address": null, // set by server
          // "client_user_agent": null // set by server
        },
        custom_data: {
          //  content_category,
          content_name,
          // content_type,
          // content_ids,
          // contents,
          // currency,
          //  value,
          ...(transaction_id ? { order_id: transaction_id } : {}),
        },
      },
    ],
  });

  // tiktok

  // https://business-api.tiktok.com/portal/docs?id=1739584805049345

  // seems tiktok doesn't support arbitrary page views
  // it only supports product views
  // so beccause this is missing, if you need it,
  // you might better use the  sendTaggingViewContentProduct funtion below on every page instead.

  // if ((window as any)?.ttq?.track)
  //     (window as any).ttq.track('ViewContent', {
  //         "value": 0, // number. Value of the order or items sold. Example: 100.
  //         "currency": currency, // string. The 4217 currency code. Example: "USD".
  //         "contents": [
  //             {
  //                 "item_group_id": location.pathname, // string. ID of the product. Example: "1077218".
  //                 "content_type": "product_group", // string. Either product or product_group.
  //                 "content_name": content_name // string. The name of the page or product. Example: "shirt".
  //             }
  //         ]
  //     })
};

export const sendSegmentTrackViewContent = async ({
  content_name = default_content_name,
  content_ids = default_content_ids,
  content_category = default_content_category,
  currency = default_currency,
  value = default_value,
  eventID,
}: {
  content_name?: string;
  content_ids?: string[];
  content_category?: string;
  currency?: string;
  value?: string;
  eventID: string;
}): Promise<void> => {
  if ((window as any)?.analytics) {
    (window as any).analytics.track('View Content', {
      content_name,
      content_ids,
      content_category,
      currency,
      value,
      eventID,
    });
  }
};

export const sendTaggingViewContentProduct = async ({
  content_name = default_content_name,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
} = {}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  sendSegmentTrackViewContent({
    eventID: fbVariables.event_id,
  })

  sendTaggingFacebookEvent({
    data: [
      {
        test_event_code: getTestEventCode(),
        // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
        // Required for Advantage+ catalog ads: contents or content_ids

        event_name: 'ViewContent',
        // "event_time": 1702150504,  // set by server
        action_source: 'website',
        event_id: fbVariables.event_id,
        event_source_url: location.href,
        user_data: {
          ...fbVariables.fbpfbc,
          // ...(email ? { email: await hashEmail(email) } : {})
          // "client_ip_address": null, // set by server
          // "client_user_agent": null // set by server
        },
        custom_data: {
          content_category,
          content_name,
          content_type,
          content_ids,
          contents,
          currency,
          value,
          ...(transaction_id ? { order_id: transaction_id } : {}),
        },
      },
    ],
  });

  // tiktok
  if ((window as any)?.ttq?.track)
    (window as any).ttq.track('ViewContent', {
      value: value, // number. Value of the order or items sold. Example: 100.
      currency: currency, // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: content_ids[0], // string. ID of the product. Example: "1077218".
          content_type: 'product', // string. Either product or product_group.
          content_name: content_name, // string. The name of the page or product. Example: "shirt".
        },
      ],
    });
};

export const sendTaggingAddToCart = async ({
  content_name = default_content_name,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
} = {}): Promise<void> => {
  const fbVariables = getFacebookVariables();
  sendTaggingFacebookEvent({
    data: [
      {
        test_event_code: getTestEventCode(),
        // content_ids, content_name, content_type, contents, currency, value

        // Optional.
        // Required for Advantage+ catalog ads: contents

        event_name: 'AddToCart',
        // "event_time": 1702150504,  // set by server
        action_source: 'website',
        event_id: fbVariables.event_id,
        event_source_url: location.href,
        user_data: {
          ...fbVariables.fbpfbc,
          // ...(email ? { email: await hashEmail(email) } : {})
          // "client_ip_address": null, // set by server
          // "client_user_agent": null // set by server
        },
        custom_data: {
          content_category,
          content_name,
          content_type,
          content_ids,
          contents,
          currency,
          value,
          ...(transaction_id ? { order_id: transaction_id } : {}),
        },
      },
    ],
  });

  // tiktok
  if ((window as any)?.ttq?.track)
    (window as any).ttq.track('AddToCart', {
      value: value, // number. Value of the order or items sold. Example: 100.
      currency: currency, // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: content_ids[0], // string. ID of the product. Example: "1077218".
          content_type: 'product', // string. Either product or product_group.
          content_name: content_name, // string. The name of the page or product. Example: "shirt".
        },
      ],
    });
};

export async function hashEmail(email: string) {
  try {
    // Encode the email string into a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(email.toLocaleLowerCase());

    // Hash the data with SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the ArrayBuffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  } catch (e) {
    console.log(e?.stack ? e?.stack : e);
  }
  return undefined;
}

// // Example usage
// const email = 'user@example.com';
// hashEmail(email).then(hash => console.log(hash));

export const sendTaggingCustomizeProduct = async ({
  content_name = default_content_name,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
} = {}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  //faceebook
  sendTaggingFacebookEvent({
    data: [
      {
        test_event_code: getTestEventCode(),
        // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
        // Required for Advantage+ catalog ads: contents or content_ids

        event_name: 'CustomizeProduct',
        // "event_time": 1702150504,  // set by server
        action_source: 'website',
        event_id: fbVariables.event_id,
        event_source_url: location.href,
        user_data: {
          ...fbVariables.fbpfbc,
          ...(email ? { email: await hashEmail(email) } : {}),
          // "client_ip_address": null, // set by server
          // "client_user_agent": null // set by server
        },
        custom_data: {
          content_category,
          content_name,
          content_type,
          content_ids,
          contents,
          currency,
          value,
          ...(transaction_id ? { order_id: transaction_id } : {}),
        },
      },
    ],
  });

  // tiktok does not have tracking product customization
  //
};

// usage example of below
//
//     const order_all_submits = useSelector((state: any) => state.order)
//     const {
//     step1FormData: { _id: orderId1, },
//     step2FormData: { _id: orderId2, },
//     step3FormData: { orderId: orderId3, },
//     step4FormData: { orderId: orderId4, }
//     } = order_all_submits || {}
//     const finalOrderId = (orderId4 || orderId3 || orderId2 || orderId1 || undefined) as string | undefined
//
//     useEffect(() => {
//     if (finalOrderId)
//         sendTaggingInitiateCheckout({ transaction_id: finalOrderId });
//     }, [finalOrderId])
//
export const sendTaggingInitiateCheckout = async ({
  content_name = default_content_name,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
} = {}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  await Promise.all([
    // google ads
    new Promise<void>((resolve) => {
      let once = true;
      const resolveOnce = () => {
        if (once) {
          once = false;
          resolve();
        }
      };
      try {
        if ((window as any)?.gtag)
          (window as any).gtag('event', 'conversion', {
            send_to: 'AW-10781840734/BhouCKDGt5cZEN6ql5Uo', // 	Submit Song Order Btn
            ...(transaction_id ? { transaction_id: transaction_id } : {}),
            event_callback: resolveOnce,
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolveOnce();
      }
    }), // Promise.all

    async () => {
      try {
        analytics.track('Checkout Started', {
          name: content_name.substring(0, 50),
          product_id: content_ids[0],
          category: content_category,
          properties: {
            currency,
            revenue: value,
          },
          messageId: fbVariables.event_id,
        });
      } catch (e) {
        console.error(e?.stack ? e?.stack : e);
      }
    }, // Promise.all

    // facebook
    sendTaggingFacebookEvent({
      data: [
        {
          test_event_code: getTestEventCode(),
          // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
          // Required for Advantage+ catalog ads: contents or content_ids

          event_name: 'InitiateCheckout',
          // "event_time": 1702150504,  // set by server
          action_source: 'website',
          event_id: fbVariables.event_id,
          event_source_url: location.href,
          user_data: {
            ...fbVariables.fbpfbc,
            ...(email ? { email: await hashEmail(email) } : {}),
            // "client_ip_address": null, // set by server
            // "client_user_agent": null // set by server
          },
          custom_data: {
            content_category,
            content_name,
            content_type,
            content_ids,
            contents,
            currency,
            value,
            ...(transaction_id ? { order_id: transaction_id } : {}),
          },
        },
      ],
    }), // Promise.all

    sendTaggingFacebookPixelStandardEvent(
      'InitiateCheckout',
      {
        content_category,
        content_name,
        content_type,
        content_ids,
        contents,
        currency,
        ...(transaction_id ? { order_id: transaction_id } : {}),
      },
      fbVariables.event_id
    ), // Promise.all

    // tiktok
    new Promise<void>((resolve) => {
      try {
        if ((window as any)?.ttq?.track)
          (window as any).ttq.track('InitiateCheckout', {
            value: value, // number. Value of the order or items sold. Example: 100.
            currency: currency, // string. The 4217 currency code. Example: "USD".
            contents: [
              {
                content_id: content_ids[0], // string. ID of the product. Example: "1077218".
                content_type: 'product', // string. Either product or product_group.
                content_name: content_name, // string. The name of the page or product. Example: "shirt".
              },
            ],
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolve();
      }
    }),
  ]);
};

export const sendTaggingRegister = async ({
  content_name = default_content_name,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  await Promise.all([
    // google ads
    new Promise<void>((resolve) => {
      let once = true;
      const resolveOnce = () => {
        if (once) {
          once = false;
          resolve();
        }
      };
      try {
        if ((window as any)?.gtag)
          (window as any).gtag('event', 'conversion', {
            send_to: 'AW-10781840734/2l70CJSFtZcZEN6ql5Uo', // 	Create Account - Register
            ...(transaction_id ? { transaction_id: transaction_id } : {}),
            event_callback: resolveOnce,
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolveOnce();
      }
    }),

    //   ,// Promise.all
  ]);
};

export const sendTaggingGoToCheckOutButton = async ({
  content_name = default_content_name,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  await Promise.all([
    // google ads
    new Promise<void>((resolve) => {
      let once = true;
      const resolveOnce = () => {
        if (once) {
          once = false;
          resolve();
        }
      };
      try {
        if ((window as any)?.gtag)
          (window as any).gtag('event', 'conversion', {
            send_to: 'AW-10781840734/9o6gCJyYx5cZEN6ql5Uo', // 	Go To Checkout BTN
            ...(transaction_id ? { transaction_id: transaction_id } : {}),
            event_callback: resolveOnce,
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolveOnce();
      }
    }),

    //   ,// Promise.all
  ]);
};

// export const sendTaggingPurchasePayButton = async (
//     content_name: string = default_content_name,
//     email = undefined,
//     content_ids: Array<string> = default_content_ids,
//     contents: Array<{ 'id': string | number; 'quantity': number; }> = default_contents,
//     content_type = default_content_type,
//     currency = default_currency,
//     value = default_value
// ): Promise<void> => {
//     const fbVariables = getFacebookVariables();

//     await Promise.all([

//         // google ads
//         new Promise<void>((resolve) => {
//             try {
//                 if ((window as any)?.gtag)
//                     (window as any).gtag('event', 'conversion', {
//                         'send_to': 'AW-10781840734/4_4ICNCfx5cZEN6ql5Uo', // 	Purchase - Pay BTN
//                         'event_callback': resolve
//                     });
//             } catch (e) { console.log(e) }
//         })

//         //   ,// Promise.all
//     ]);
// }

export const sendTaggingPurchase = async ({
  content_name = default_content_name,
  content_category = default_content_category,
  email = undefined,
  content_ids = default_content_ids,
  contents = default_contents,
  content_type = default_content_type,
  currency = default_currency,
  value = default_value,
  transaction_id = undefined,
}: {
  content_name?: string;
  content_category?: string;
  email?: string | undefined;
  content_ids?: Array<string>;
  contents?: Array<{ id: string | number; quantity: number }>;
  content_type?: string;
  currency?: string;
  value?: string;
  transaction_id?: string | undefined;
}): Promise<void> => {
  const fbVariables = getFacebookVariables();

  await Promise.all([
    // google ads
    new Promise<void>((resolve) => {
      let once = true;
      const resolveOnce = () => {
        if (once) {
          once = false;
          resolve();
        }
      };
      try {
        if ((window as any)?.gtag)
          (window as any).gtag('event', 'conversion', {
            send_to: 'AW-10781840734/4_4ICNCfx5cZEN6ql5Uo', // Purchase - Pay BTN
            ...(transaction_id ? { transaction_id: transaction_id } : {}),
            event_callback: resolveOnce,
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolveOnce();
      }
    }),

    
    async () => {
      try {
        analytics.track('Purchase', {
          name: content_name.substring(0, 50),
          product_id: content_ids[0],
          category: content_category,
          properties: {
            currency,
            revenue: value,
          },
          messageId: fbVariables.event_id,
        });
      } catch (e) {
        console.error(e?.stack ? e?.stack : e);
      }
    },

    // facebook
    sendTaggingFacebookEvent({
      data: [
        {
          test_event_code: getTestEventCode(),
          // required: content_ids, content_category, content_name, content_type, contents, currency, value Optional.
          // Required for Advantage+ catalog ads: contents or content_ids

          event_name: 'Purchase',
          // "event_time": 1702150504,  // set by server
          action_source: 'website',
          event_id: fbVariables.event_id,
          event_source_url: location.href,
          user_data: {
            ...fbVariables.fbpfbc,
            ...(email ? { email: await hashEmail(email) } : {}),
            // "client_ip_address": null, // set by server
            // "client_user_agent": null // set by server
          },
          custom_data: {
            content_category,
            content_name,
            content_type,
            content_ids,
            contents,
            currency,
            value,
            ...(transaction_id ? { order_id: transaction_id } : {}),
          },
        },
      ],
    }),

    // facebook pixel
    sendTaggingFacebookPixelStandardEvent(
      'Purchase',
      {
        content_category,
        content_name,
        content_type,
        content_ids,
        contents,
        currency,
        value,
        ...(transaction_id ? { order_id: transaction_id } : {}),
      },
      fbVariables.event_id
    ),

    // tiktok
    new Promise<void>((resolve) => {
      try {
        if ((window as any)?.ttq?.track)
          (window as any).ttq.track('AddPaymentInfo', {
            value: value, // number. Value of the order or items sold. Example: 100.
            currency: currency, // string. The 4217 currency code. Example: "USD".
            contents: [
              {
                content_id: content_ids[0], // string. ID of the product. Example: "1077218".
                content_type: 'product', // string. Either product or product_group.
                content_name: content_name, // string. The name of the page or product. Example: "shirt".
              },
            ],
          });

        if ((window as any)?.ttq?.track)
          (window as any).ttq.track('PlaceAnOrder', {
            value: value, // number. Value of the order or items sold. Example: 100.
            currency: currency, // string. The 4217 currency code. Example: "USD".
            contents: [
              {
                content_id: content_ids[0], // string. ID of the product. Example: "1077218".
                content_type: 'product', // string. Either product or product_group.
                content_name: content_name, // string. The name of the page or product. Example: "shirt".
              },
            ],
          });

        if ((window as any)?.ttq?.track)
          (window as any).ttq.track('CompletePayment', {
            value: value, // number. Value of the order or items sold. Example: 100.
            currency: currency, // string. The 4217 currency code. Example: "USD".
            contents: [
              {
                content_id: content_ids[0], // string. ID of the product. Example: "1077218".
                content_type: 'product', // string. Either product or product_group.
                content_name: content_name, // string. The name of the page or product. Example: "shirt".
              },
            ],
          });
      } catch (e) {
        console.log(e);
      } finally {
        resolve();
      }
    }),
  ]); // Promise.all
};

export async function trackIdentifyUser(email: string, userid: string) {

  sendSegmentIdentify(userid, { email });

  const email_hash = await hashEmail(email);

  // user support chat
  try {
    const pagesense = ((window as any).pagesense as any[]) || [];
    // let index = pagesense.findIndex((item: any) => item[0] === 'identifyUser');
    // if (index !== -1) pagesense[index][1] = email;
    // else
    pagesense.push(['identifyUser', email]);
  } catch (e) {
    console.error(e?.stack ? e?.stack : e);
  }

  //TikTok
  try {
    if ((window as any)?.ttq?.identify) {
      (window as any).ttq.identify({
        email: email_hash, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
        // "phone_number": "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
        // "external_id": "<hashed_extenal_id>" // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
      });
    }
  } catch (e) {
    console.error(e?.stack ? e?.stack : e);
  }

  //Google Analytics and Ads
  try {
    if ((window as any).gtag) {
      (async () => {
        (window as any).gtag('config', 'TAG_ID', {
          user_id: email_hash,
        });
      })();
    }
  } catch (e) {
    console.error(e?.stack ? e?.stack : e);
  }

  //Facebook Serverside
  try {
    sendTaggingFacebookIdentify(window.document.title, email_hash);
  } catch (e) {
    console.error(e?.stack ? e?.stack : e);
  }
}

function isLoadedInIframe() {
  return window.self !== window.top;
}

export async function trackVirtualPageVisit() {
  // pagesense can track it by iteself
  // no need

  //TikTok
  // if ((window as any).ttq)
  //   (window as any).page();
  //
  // dont knowyet if requered

  //Google Analytics and Ads
  // google tag manger advanced tracking, tracks the page urls, from history change, but NOT the page titles, so here is the event again now with the title
  if ((window as any).gtag)
    (window as any).gtag('event', 'page_view', {
      page_title: document.title,
      page_location: location.href,
    });

  // we had a problem with embeded page ads in facebook. so i added tracking of it in an event.
  if (isLoadedInIframe())
    (window as any).gtag('event', 'iframe_page_view', {
      page_title: document.title,
      page_location: location.href,
      event_category: 'Iframe',
      non_interaction: true, // This ensures the event does not affect bounce rate
    });

  //Facebook Serverside

  const fbVariables = getFacebookVariables();


  sendTaggingViewContentPage({ content_name: window.document.title, fbVariables });


  try {
    analytics.page({
      name: document.title.substring(0, 50),
      properties: {
        title: document.title,
        url: location.href
      },
      messageId: fbVariables.event_id,
    });
  } catch (e) {
    console.error(e?.stack ? e?.stack : e);
  }


}

const defaultObject = {
  // general
  getTestEventCode,
  getFacebookVariables,
  sendTaggingFacebookEvent,
  sendTaggingFacebookPixelEvent,
  sendTaggingFacebookPixelCustomEvent,
  sendTaggingCustomEvent,
  sendTaggingFacebookIdentify,
  hashEmail,
  // useful
  //
  sendTaggingAddToCart,
  sendTaggingViewContentPage,
  sendTaggingViewContentProduct,
  sendTaggingCustomizeProduct,
  sendTaggingInitiateCheckout,
  sendTaggingPurchase,

  //google
  sendTaggingGoToCheckOutButton,
  //others
  trackVirtualPageVisit,
  trackIdentifyUser,

  // Referral
  getReferralId,
  clearReferralId,

  // debug marketing
  getUTM,
  getUTMMedium,
  getUTMSource,
  getUTMCampaign,

  getMarketingDebugData,
  getFirstEnterLink,
  getFirstReferralLink,
  getFirstCookie,
};

export default defaultObject;

// example usage as import:
// import { useEffect } from 'react'
// import { sendTaggingInitiateCheckout } from '~services/tagging'
// useEffect(() => {
//     sendTaggingInitiateCheckout()
// }, [])

// example usage for umd library:
// Tagging.sendTaggingViewContentPage(); // no product info, name is page title
// Tagging.sendTaggingViewContentProduct(); // with product info, name is prodcut name
//

// // Example Usage for basics
//
// the structure is with accordance to objects of import * as bizSdk from 'facebook-nodejs-business-sdk';
// the assignment of object data to objects to objects of facebook-nodejs-business-sdk is defiend in my file api-server/src/modules/tagging/facebook-conversion-api.service.ts
//
// useEffect(() => {
//     (async () => {
//         const fbVariables = getFacebookVariables();
//         sendTaggingEvent({
//             "data": [
//                 {
//                     "event_name": "ViewContent",
//                     // "event_time": 1702150504,  // set by server
//                     "action_source": "website",
//                     "event_id": fbVariables.event_id, // for deduplication for sending events in parallel in client and in server
//                     "event_source_url": location.href,
//                     "user_data": {
//                         "fbc": fbVariables.fbc,
//                         "fbp": fbVariables.fbp,
//                         "email": await hashEmail(email),
//                         // "client_ip_address": null, // set by server
//                         // "client_user_agent": null // set by server
//                     },
//                     "custom_data": {
//                         "content_name": "Landing page: Page Name"
//                     }
//                 }
//             ]
//         });
//     })();
// }, [])
