import { CacheProvider, EmotionCache } from '@emotion/react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'simplebar-react/dist/simplebar.min.css';
import { MotionLazyContainer } from '~components/animate';
import ProgressBar from '~components/progress-bar';
import { SettingsProvider, ThemeSettings } from '~components/settings';
import SnackbarProvider from '~components/snackbar';
import { AuthProvider } from '~config/auth/JwtContext';
import { Satoshi } from '~config/fonts';
import ThemeLocalization from '~config/locales';
import '~config/locales/i18n';
import ThemeProvider from '~config/theme';
import createEmotionCache from '~config/utils/createEmotionCache';
import { ModalDialogsProvider } from '~screens/shared/modal-dialogs/modal-dialogs-provider';
import store, { persistor } from '~store/index';
import '~pages/../public/fonts/barlow/index.css';
import '~pages/../public/fonts/public-sans/index.css';
import '~pages/../public/fonts/satoshi/index.css';
import 'simplebar-react/dist/simplebar.min.css';
import '~styles/global.css';
import { useEffect, useState } from 'react';
import { getReferralId, trackIdentifyUser, trackVirtualPageVisit, getUTM, getMarketingDebugData } from '~services/tagging';
//import LogRocket from 'logrocket';

import { setSavedPath, setUser } from '~screens/auth/store/authSlice';
import toast from 'react-hot-toast';
import { baseUrl } from '~config/base-url';
import { fetchWithToken } from '~helper/index';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getQueryParam } from '~services/cookie';
const clientSideEmotionCache = createEmotionCache();
type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};
const handleSocialLoginRedirect = async (update_user_access_token: string, savedPath: string, replace: any, asPath: any, dispatch: any, setUser: any) => {
  try {
    if (!update_user_access_token) {
      return;
    } else if (update_user_access_token == 'facebook_failed') {
      setTimeout(() => {
        toast.error('Facebook login was unsuccesfull. Please try another login method.');
      }, 1000);
    } else if (update_user_access_token == 'google_failed') {
      setTimeout(() => {
        toast.error('Google login was unsuccesfull. Please try another login method.');
      }, 1000);
    } else {
      const response = await fetchWithToken(update_user_access_token, baseUrl + '/api/user/profile');
      // try fetch user with user token
      if (!response.ok) {
        const error = await response.json();
        toast.error(error.message || 'An error occurred');
      } else {
        const respData = await response.json();
        if (respData) {
          // if user fetched and has data update user
          dispatch(setUser({
            access_token: update_user_access_token,
            userDto: respData
          }));
        }
      }
    }
    let redirectToURL = savedPath;
    redirectToURL = redirectToURL.replace(/update_user_access_token=.*?(&|$)/, ''); // remove token if exists
    redirectToURL = redirectToURL.replace(/(\?|&)$/, ''); // if ends with questionmark or ampersand remove ending questionmark or ampersand
    redirectToURL = redirectToURL.replace(/^\/?((login\/?|signup\/?).*?)(\?.+|)$/, '$3'); // do not redirect to login or signup, keep query string: if path starts with login or signup it replaces all path until query string or no query string, with query string literal

    if (asPath != redirectToURL) {
      dispatch(setSavedPath(''));
      replace(redirectToURL);
    }
  } catch (error) {
    toast.error('Something went wrong. Please try again.');
  }
};
export type AfterInitProps = {
  children?: any;
};
export const AfterInit: React.FC<AfterInitProps> = ({
  children = null
}) => {
  const dispatch = useDispatch();
  const {
    replace,
    asPath
  } = useRouter();
  const {
    user,
    savedPath
  } = useSelector((state: any) => state.auth) || {};
  useEffect(() => {
    const email = user?.userDto?.role?.name === 'Visitor' || !user ? user?.userDto?.gifterEmail : user?.userDto?.email;
    if (email) {
      // suport

      trackIdentifyUser(email, user?.userDto?._id || ''); // pagesense

      //
      // LogRocket.identify(user?.userDto?._id||'', {
      //   name: `${user?.userDto?.firstName||''} ${user?.userDto?.lastName||''}`.trim()||"na",
      //   email: email,
      //   subscriptionType: user?.userDto?.role?.name||"na"
      // });
      // LogRocket.identify(user?.userDto?._id||'', {
      //   name: `${user?.userDto?.firstName||''} ${user?.userDto?.lastName||''}`.trim()||"na",
      //   email: email,
      //   subscriptionType: user?.userDto?.role?.name||"na"
      // });
      // if ((window as any)?.clarity)
      //   (window as any).clarity("identify", email)
    }
  }, [user?.userDto?.role?.name, user?.userDto?.gifterEmail, user?.userDto?.email]);

  ///handle social login token
  useEffect(() => {
    const update_user_access_token = getQueryParam('update_user_access_token') || '';
    if (update_user_access_token) {
      handleSocialLoginRedirect(update_user_access_token, savedPath, replace, asPath, dispatch, setUser);
    }
  }, []);
  //

  return children;
};
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}
export default function MyApp(props: MyAppProps) {
  const {
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache
  } = props;
  const router = useRouter();
  useEffect(() => {
    getReferralId();
    getUTM();
    getMarketingDebugData();
    // LogRocket.init('mfvxmi/order');
  }, []);
  useEffect(() => {
    trackVirtualPageVisit();
  }, [router.asPath]);
  useEffect(() => {
    if ((window as any).location.protocol === 'http:' && ['songoven.com', 'www.songoven.com'].includes((window as any).location.hostname)) {
      (window as any).location.href = (window as any).location.href.replace('http:', 'https:');
    }
  }, []);
  const getLayout = Component.getLayout ?? (page => page);
  return <main className={Satoshi.className} data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
          <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        </Head>
        <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
          <ModalDialogsProvider data-sentry-element="ModalDialogsProvider" data-sentry-source-file="_app.tsx">
            <SettingsProvider data-sentry-element="SettingsProvider" data-sentry-source-file="_app.tsx">
              <MotionLazyContainer data-sentry-element="MotionLazyContainer" data-sentry-source-file="_app.tsx">
                <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
                  <Provider {...{
                  store
                }} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
                    <AfterInit data-sentry-element="AfterInit" data-sentry-source-file="_app.tsx" />
                    <ThemeSettings data-sentry-element="ThemeSettings" data-sentry-source-file="_app.tsx">
                      <ThemeLocalization data-sentry-element="ThemeLocalization" data-sentry-source-file="_app.tsx">
                        <SnackbarProvider data-sentry-element="SnackbarProvider" data-sentry-source-file="_app.tsx">
                          <ProgressBar data-sentry-element="ProgressBar" data-sentry-source-file="_app.tsx" />
                          {getLayout(<Component {...pageProps} />)}
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                  </Provider>
                </ThemeProvider>
              </MotionLazyContainer>
            </SettingsProvider>
          </ModalDialogsProvider>
        </AuthProvider>
      </CacheProvider>
      <Toaster position="top-right" data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
    </main>;
}