// cookie utility functions

function getEffectiveTopLevelDomain() {
  var hostname = window.location.hostname;
  // Split the hostname into parts
  var parts = hostname.split('.');

  // Check for IP address or localhost
  if (
    parts.length === 4 &&
    parts.every((part) => Number.isInteger(Number(part)))
  ) {
    // It's an IP address, return the full hostname
    return hostname;
  }
  if (hostname === 'localhost' || parts.length === 1) {
    // Localhost or single-part hostname, return as is
    return hostname;
  }

  // Check if the last part is two letters (ccTLD) and the part before it is three letters (SLD)
  if (
    parts.length > 2 &&
    parts[parts.length - 2].length <= 3 &&
    parts[parts.length - 1].length === 2
  ) {
    // Likely a second-level domain like '.co.uk', take the last three parts
    return parts.slice(-3).join('.');
  } else {
    // Otherwise, take the last two parts for the TLD
    // This does not accurately handle all SLDs but works for many cases
    return parts.slice(-2).join('.');
  }
}

export function clearStoredValue(name: string) {
  setCookie(name, '', -1);
  localStorage.removeItem(name);
}

export function setCookie(name: string, value: string, daysToExpire: number) {
  const date = new Date();
  date.setDate(date.getDate() + daysToExpire);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/;domain=.${getEffectiveTopLevelDomain()}; secure; samesite=Lax`;
}

export const getCookie = (name: string): string | undefined => {
  const cookieValue = `; ${document.cookie}`;
  const parts = cookieValue.split(`; ${name}=`);
  return (
    (parts.length === 2 ? parts.pop()?.split(';').shift() : undefined) ||
    undefined
  );
};

export function setLocalStorageExpire(
  name: string,
  value: string,
  daysToExpire: number
): void {
  const date = new Date();
  date.setDate(date.getDate() + daysToExpire);
  const item = {
    value: value,
    expires: date.toISOString(),
  };
  localStorage.setItem(name, JSON.stringify(item));
}

export function getLocalStorageExpire(name: string): string | undefined {
  try {
    const storedItem = localStorage.getItem(name);
    if (!storedItem) return undefined;

    const { value, expires } = JSON.parse(storedItem);

    const now = new Date();
    const expiryDate = new Date(expires);

    if (now < expiryDate) {
      return value;
    } else {
      localStorage.removeItem(name);
      return undefined;
    }
  } catch (e) {
    console.log(e);
  }

  return undefined;
}

export function setLocalStorage(name: string, value: string): void {
  return setLocalStorageMulti(name, value);
  // localStorage.setItem(name, JSON.stringify(value));
}

export function getLocalStorage(name: string): string | undefined {
  try {
    const storedItemMulti = getLocalStorageMulti(name);
    if (storedItemMulti!==undefined) {
      return storedItemMulti;
    }

    const storedItem = localStorage.getItem(name);
    if (storedItem!==undefined && storedItem!==null) {
      const value = JSON.parse(storedItem);
      // if (value.length > 240 && value.length < 260 && value.endsWith('...')) {
      if ( typeof value === 'string' && value.endsWith('...')) {
        localStorage.removeItem(name);
        return undefined;
      }
      else
      {
        localStorage.removeItem(name);
        setLocalStorageMulti(name, value);
        return value;
      }
      return value;
    }    
  } catch (e) {
    console.log(e);
  }

  return undefined;
}
//////////
export function setLocalStorageExpireMulti(name: string, value: string, daysToExpire: number): void {
  const date = new Date();
  date.setDate(date.getDate() + daysToExpire);
  const expiryDate = date.toISOString();

  const parts = Math.ceil(value.length / 247);
  for (let i = 0; i < parts; i++) {
    localStorage.setItem(`${name}_part${i}`, value.substring(i * 247, (i + 1) * 247));
  }
  localStorage.setItem(`${name}_meta`, JSON.stringify({ parts, expires: expiryDate }));
}

export function getLocalStorageExpireMulti(name: string): string | undefined {
  try {
    const metaItem = localStorage.getItem(`${name}_meta`);
    if (!metaItem) return undefined;

    const { parts, expires } = JSON.parse(metaItem);
    const now = new Date();
    const expiryDate = new Date(expires);

    if (now >= expiryDate) {
      removeLocalStorageMulti(name);
      return undefined;
    }

    let value = "";
    for (let i = 0; i < parts; i++) {
      const part = localStorage.getItem(`${name}_part${i}`);
      if (part) value += part;
    }
    return value;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function setLocalStorageMulti(name: string, value1: string): void {
  const value = JSON.stringify(value1);
  const parts = Math.ceil(value.length / 247);
  for (let i = 0; i < parts; i++) {
    localStorage.setItem(`${name}_part${i}`, value.substring(i * 247, (i + 1) * 247));
  }
  localStorage.setItem(`${name}_meta`, JSON.stringify({ parts }));
}

export function getLocalStorageMulti(name: string): string | undefined {
  try {
    const metaItem = localStorage.getItem(`${name}_meta`);
    if (!metaItem) return undefined;

    const { parts } = JSON.parse(metaItem);
    let value = "";
    for (let i = 0; i < parts; i++) {
      const part = localStorage.getItem(`${name}_part${i}`);
      if (part) value += part;
    }
    const value1 = JSON.parse(value);
    return value1;
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function removeLocalStorageMulti(name: string): void {
  const metaItem = localStorage.getItem(`${name}_meta`);
  if (!metaItem) return;

  const { parts } = JSON.parse(metaItem);
  for (let i = 0; i < parts; i++) {
    localStorage.removeItem(`${name}_part${i}`);
  }
  localStorage.removeItem(`${name}_meta`);
}

//////////

// Utility function to get the param from the query string
export function getQueryParam(param: string): string | undefined {
  let w = undefined;
  try { w = window; } catch (e) { }
  // @ts-ignore: Suppress error about 'this' implicitly having type 'any'
  try { w = (function () { return this; })(); } catch (e) { }

  let l = w?.location;
  if (!l) { try { l = location; } catch (e) { } }

  if (!(l && l?.search)) return undefined;
  const urlParams = new URLSearchParams(l.search);
  return urlParams.get(param) || undefined;
}

// get query param from url
export function getQueryParamURL(
  url: string,
  param: string
): string | undefined {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get(param) || undefined;
}

/// save both functions

function storeValue(
  name: string,
  value: string,
  expireDays: number | undefined = undefined
): void {
  // Set the value to expire after 30 days
  if (expireDays) {
    setCookie(name, value, expireDays);
    setLocalStorageExpireMulti(name, value, expireDays);
  } else {
    setCookie(name, value, 365);
    setLocalStorageMulti(name, value);
  }
}

function getStoredValueAndEnsureSaved(
  name: string,
  expireDays: number | undefined = undefined
): string | undefined {
  // Attempt to get the value from a cookie
  const cookieValue = getCookie(name);

  // If not found in the cookie, check local storage
  let localStorageValue;
  if (expireDays){

    localStorageValue = getLocalStorageExpire(name);
    if(localStorageValue)
    {
      localStorage.removeItem(name);
      setLocalStorageExpireMulti(name, localStorageValue as string, expireDays);
    }

    localStorageValue = getLocalStorageExpireMulti(name);
  } 
  else{
    
    localStorageValue = getLocalStorage(name);
    if(localStorageValue)
    {
      localStorage.removeItem(name);
      setLocalStorageMulti(name, localStorageValue as string);
    }
    localStorageValue = getLocalStorageMulti(name);
  } 

  // ensure value is stored the other way too, with cookie is priority and local storage is backup

  if (cookieValue && cookieValue !== localStorageValue) {
    if (expireDays)
    {
      setLocalStorageExpireMulti(name, cookieValue as string, expireDays);
    }
    else
    {
      setLocalStorageMulti(name, cookieValue as string);
    }
  }

  if (localStorageValue && !cookieValue && name !== "_fbc") {
    if (expireDays) setCookie(name, localStorageValue as string, expireDays);
    else setCookie(name, localStorageValue as string, 365);
  }

  if (cookieValue) {
    return cookieValue;
  }
  if (localStorageValue) {
    return localStorageValue;
  }
  return undefined;
}

export function storeGetValue(
  value: string | undefined,
  name: string,
  expireDays: number | undefined = undefined
): string | undefined {
  if (value) {
    storeValue(name, value, expireDays);
  } else {
    value = getStoredValueAndEnsureSaved(name, expireDays);
  }
  return value;
}
